// Enter all your detials in this file
// Logo images
// import logo from "./assets/logo2.svg";
import robsRLogo from "./assets/robsRLogo.png";
// import me from "./assets/me.png"
// Profile Image
// Tech stack images
import alexa from "./assets/techstack/alexa.png";
import apiGateway from "./assets/techstack/apiGateway.png";
import aws from "./assets/techstack/aws.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import css from "./assets/techstack/css.png";
import docker from "./assets/techstack/docker.png";
import dynamoDB from "./assets/techstack/dynamoDB.png";
import figma from "./assets/techstack/figma.png";
import git from "./assets/techstack/git.png";
import github from "./assets/techstack/github.png";
import go from "./assets/techstack/go.png";
import html from "./assets/techstack/html.png";
import js from "./assets/techstack/js.png";
import lambda from "./assets/techstack/lambda.png";
import nodejs from "./assets/techstack/nodejs.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import sass from "./assets/techstack/sass.png";
import tailwind from "./assets/techstack/tailwind.png";
import vscode from "./assets/techstack/vscode.png";

// Porject Images
// import projectImage1 from "./assets/projects/project1.jpg";
// import projectImage2 from "./assets/projects/project2.jpg";
// import projectImage3 from "./assets/projects/project3.jpg";

// import paraphrasingTool from "./assets/projects/paraphrasingTool.jpg"

// App Images
import monsterHunterIcon from "./assets/apps/MONSTER_HUNTER_APP_ICON_LARGE.png"
import trueOrFalseIcon from './assets/apps/TRUE_OR_FALSE_APP_ICON_LARGE.png'
import dogTeaserIcon from './assets/apps/DOG_TEASER_APP_ICON_LARGE.png'

// Logos
export const logos = {
  logogradient: robsRLogo, // was logogradient
  logo: robsRLogo, // was logo
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Rocsoft",
  tagline: "We build AI Applications for voice assistants",
  img: robsRLogo, // profile pricture
 about: `Welcome to Rocsoft, where innovation meets intelligence to redefine the way we interact with technology. At Rocsoft, we are passionate pioneers in the realm of software engineering, dedicated to crafting cutting-edge AI applications that enhance and revolutionize voice assistant technologies. Our expertise lies in developing sophisticated, user-friendly applications for voice assistants such as Alexa. 
  
Our mission is simple yet ambitious: to push the boundaries of artificial intelligence to unlock new potentials and possibilities. We achieve this by harnessing the latest advancements in AI research, combined with our deep understanding of software engineering, to develop applications that are not only smart but also reliable and scalable.

At Rocsoft, we believe in the power of voice technology as a transformative tool for everyday life. We're committed to delivering excellence and innovation in every project. We collaborate closely with our clients to understand their needs and challenges, ensuring that our solutions are tailored to meet their unique requirements.

Why choose Rocsoft? Because we are not just developers; we are creators, thinkers, and problem solvers dedicated to excellence. Join us on this exhilarating journey to explore the limitless possibilities of AI and voice technology.

Experience the future of voice interaction with Rocsoft – where your voice is the command, and AI is the response.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/",
  github: "https://github.com/",
  twitter: "https://twitter.com/",
  instagram: "https://www.instagram.com/",
};

// Enter your Work Experience here
export const workDetails = [
  // {
  //   Position: "Founded Rocsoft ",
  //   Company: ` - `,
  //   Location: "Remote",
  //   Type: "Full Time",
  //   Duration: "2022 - 2023",
  // },
];

// Enter your Education Details here
export const eduDetails = [
  // {
  //   Position: "AWS Solutions Architect Associate Certification",
  //   Company: "Amazon Web Services",
  //   Location: "Online",
  //   Type: "Full Time",
  //   Duration: "2023 - present",
  // },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  go: go,
  nodejs: nodejs,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
  alexa: alexa,
  aws: aws,
  lambda: lambda,
  dynamoDB: dynamoDB,
  apiGateway: apiGateway,
  docker: docker,
};

// Enter your Project Details here
export const projectDetails = [
  // {
  //   title: "He He",
  //   image: "",
  //   description: `IDK`,
  //   techstack: "IDK",
  //   previewLink: "IDK",
  //   githubLink: "",
  // },
];

// Enter your Project Details here
export const appDetails = [
  {
    title: "Monster Hunter",
    image: monsterHunterIcon,
    description: `An interactive medieval adventure game where your decisions decide your fate. Are you brave enough to complete the Quest and track down the Hell Hounds?`,
    previewLink: "https://www.amazon.com/RP-Apps-Monster-Hunter/dp/B07RDWZ5GQ/ref=sr_1_2?dib=eyJ2IjoiMSJ9.OEwBZQ7xFsCexnlnZUCdTPHSDTFXPeII0xuSilwVS9lfkJC5uMGNYCXNKeYqkrZO4-4EQ2JPy4ULKyFEnIfV2Lw7r26-kr8ZsZxv76lkbyQ.GgZxH8R9aQvnuR6Iu1IqQALAdc5eZtC9DDpuu8BzjWE&dib_tag=se&keywords=RP+apps&qid=1715112145&rnid=13727921011&s=alexa-skills&sr=1-2",
  },
  {
    title: "True or False",
    image: trueOrFalseIcon,
    description: `Think you’re a trivia pro? Go head to head against your friends and family to see who has the most general knowledge and fastest reaction time. `,
    previewLink: "https://www.amazon.com/True-Or-False-For-Buttons/dp/B07F7S19FX/ref=sr_1_3?dib=eyJ2IjoiMSJ9.OEwBZQ7xFsCexnlnZUCdTPHSDTFXPeII0xuSilwVS9lfkJC5uMGNYCXNKeYqkrZO4-4EQ2JPy4ULKyFEnIfV2Lw7r26-kr8ZsZxv76lkbyQ.GgZxH8R9aQvnuR6Iu1IqQALAdc5eZtC9DDpuu8BzjWE&dib_tag=se&keywords=RP+apps&qid=1715112145&rnid=13727921011&s=alexa-skills&sr=1-3",
  },
  {
    title: "Dog Teaser",
    image: dogTeaserIcon,
    description: `This skill will play sounds that are designed to make your pet react in a funny and confused way, don't be surprised if they turn their head side to side. Includes high pitch noises`,
    previewLink: "https://www.amazon.com/RP-Apps-Dog-teaser/dp/B07NGQGN5P/ref=sr_1_5?dib=eyJ2IjoiMSJ9.dqy_I0vQB5lGScmHkXAoZ95Aji7aMNXufBM6G9-9g9WXV5B5ktnrcjkz__a6NPWqw2qaOpeRGWyoc2UpXADAYGbAf72Pm27yxdvLEHakteWRHz3KL4jaFxpOScWxuRP6dvaYjUUMqMgtE2y2191FC2IiHvWonV7c5IjCPtuw6ms.XWbUG0-LZG_QHo0ItYcQNsJxxD5W4NRtAv5QlotRd2Y&dib_tag=se&keywords=RP+apps&qid=1715113080&rnid=13727921011&s=alexa-skills&sr=1-5",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "rocsoft.tech@gmail.com",
  phone: "+353",
};
